@import url("https://fonts.googleapis.com/css2?family=Lilita+One&display=swap&family=Averia+Sans+Libre:wght@300;400;700&display=swap");

:root {
  --bg: #151515;

  --primary: #ffc401;
  --primary-rgb: 255, 196, 1;

  --secondary: #ff7900;
  --secondary-rgb: 255, 121, 0;

  --dark: #101920;
  --dark-rgb: 16, 25, 32;

  --light: #fffef8;
  --light-rgb: 255, 254, 248;
}

* {
  cursor: default;
  font-family: "Lilita One", cursive;
  color: var(--light);
}

body {
  background: var(--bg);
  overflow-x: hidden;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

#home {
  opacity: 0;
}

.primary-h {
  font-size: 60px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 5px;
  text-shadow: 0 0 1em rgba(var(--secondary-rgb), 0.4);
  color: var(--primary);
  margin: 150px auto 50px;
  text-align: center;
}

.primary-p {
  color: var(--light);
  font-size: 24px;
  text-align: center;
  line-height: 36px;
  font-family: "Averia Sans Libre";
}

.qty {
  font-size: 60px;
  font-weight: 900;
  letter-spacing: 2px;
}

.buy-qty {
  color: var(--light);
  font-size: 28px;
  padding: 10px;
}

.cost {
  font-size: 24px;
  text-transform: uppercase;
  line-height: 45px;
}

.qty-container {
  position: relative;
  display: inline-block;
  justify-content: center;
  width: 100%;
  max-width: 175px;
}

.qty-container div {
  height: 30px;
  background: transparent;
  margin-top: 10px;
  color: var(--primary);
  text-align: center;
  font-size: 22px;
  font-weight: 500;
  width: 100%;
  transition: 0.15s ease;
}

.qty-container button {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  border: none;
  outline: none;
  background: transparent;
  border-radius: 50px;
  font-weight: 500;
  font-size: 32px;
  cursor: pointer;
  transition: 0.15s ease;
}

.qty-container button.sub {
  left: 0.6rem;
}

.qty-container button.add {
  right: 0.6rem;
}

#space,
.stars {
  overflow: hidden;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.stars {
  background-image: radial-gradient(
      2px 2px at 130px 80px,
      var(--light),
      rgba(0, 0, 0, 0)
    ),
    radial-gradient(2px 2px at 160px 120px, var(--light), rgba(0, 0, 0, 0));
  background-repeat: repeat;
  background-size: 300px 300px;
  animation: zoom 5s infinite;
  opacity: 0;
}

.stars:nth-child(1) {
  background-position: 30% 50%;
  animation-delay: 0s;
}
.stars:nth-child(2) {
  background-position: 40% 20%;
  animation-delay: 1s;
}
.stars:nth-child(3) {
  background-position: -100% -30%;
  animation-delay: 2s;
}
.stars:nth-child(4) {
  background-position: 90% -80%;
  animation-delay: 3s;
}
.stars:nth-child(5) {
  background-position: -20% 50%;
  animation-delay: 4s;
}

@keyframes zoom {
  0% {
    opacity: 0;
    transform: scale(0.5);
    animation-timing-function: ease-in;
  }
  85% {
    opacity: 1;
    transform: scale(2);
    animation-timing-function: linear;
  }
  100% {
    opacity: 0;
    transform: scale(2.5);
  }
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary);
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

@media only screen and (max-width: 600px) {
  .qty {
    font-size: 36px;
  }

  .primary-btn {
    font-size: 18px;
  }

  .primary-h {
    font-size: 36px;
  }

  .centered {
    position: absolute;
  }

  .home-star-img {
    width: 45% !important;
  }
}
