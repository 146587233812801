@import url("https://fonts.googleapis.com/css?family=Titan+One");

.menu-container {
  background-color: var(--primary);
  border-radius: 100%;
  cursor: pointer;
  position: fixed;
  width: 200px;
  height: 200px;
  left: -100px;
  top: -100px;
  transition: 0.25s ease;
  z-index: 99;

  @media (max-width: 992px) {
    width: 200px;
    height: 200px;
    left: -100px;
    top: -100px;
  }

  &.full-menu {
    border-radius: 0px 15px 15px 15px;
    padding: 0 !important;
    width: 300px;
    height: 400px;
    left: 0;
    top: 0;
    transition: 0.25s ease;
  }
}

.menu {
  color: var(--dark);
  font-size: 2em;
  position: absolute;
  top: 115px;
  left: 125px;
  z-index: 100;
  transition: 0.25s ease;

  i {
    color: var(--dark);
    opacity: 0.75;
    cursor: pointer;
    transform: scale(1);
    transition: 0.25s ease;

    &:hover {
      opacity: 1;
      transform: scale(1.2);
      transition: 0.25s ease;
    }
  }

  @media (max-width: 992px) {
    font-size: 2em;
    top: 120px;
    left: 120px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0%;
  opacity: 0;
  visibility: hidden;
  overflow: hidden;
  z-index: 99;

  .overlayMenu {
    color: var(--dark);
    font-size: 2.5em;
    position: absolute;
    top: 10px;
    left: 25px;
    z-index: 100;
    transition: 0.25s ease;

    i {
      color: var(--dark);
      opacity: 0.75;
      cursor: pointer;
      transform: scale(1);
      transition: 0.25s ease;

      &:hover {
        opacity: 1;
        transform: scale(1.2);
        transition: 0.25s ease;
      }
    }
  }

  &.open {
    opacity: 0.9;
    visibility: visible;
    height: 100%;

    li {
      animation: fadeInRight 0.25s ease forwards;

      &:nth-of-type(1) {
        animation-delay: 0.1s;
      }

      &:nth-of-type(2) {
        animation-delay: 0.15s;
      }

      &:nth-of-type(3) {
        animation-delay: 0.2s;
      }

      &:nth-of-type(4) {
        animation-delay: 0.25s;
      }

      &:nth-of-type(5) {
        animation-delay: 0.3s;
      }

      &:nth-of-type(6) {
        animation-delay: 0.35s;
      }
    }
  }

  nav {
    position: relative;
    width: 300px;
    top: 60px;
    font-weight: 400;
    text-align: center;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: inline-block;
    position: relative;
    height: 100%;

    li {
      font-size: 24px;
      display: block;
      height: 50px;
      position: relative;
      opacity: 0;

      a {
        display: block;
        position: relative;
        color: var(--dark);
        text-decoration: none;
        overflow: hidden;
        opacity: 0.7;
        transform: scale(1);
        transition: 0.25s ease;
        cursor: pointer;

        &:hover,
        &:focus,
        &:active {
          opacity: 1;
          transform: scale(1.1);
          letter-spacing: 1px;
          transition: 0.25s ease;
        }
      }
    }
  }
}

.social-nav {
  position: fixed;
  top: 20px;
  right: 25px;
  z-index: 99;

  a {
    padding: 8px 10px 6px 10px;
    cursor: pointer;
    border-radius: 25px;
    transition: 0.25s ease;

    i {
      cursor: pointer;
      color: var(--primary);
      font-size: 28px;
      transition: 0.25s ease;

      &:hover {
        color: var(--secondary);
      }
    }

    img {
      width: 30px;
      margin-top: -9px;
      cursor: pointer;
      transition: 0.25s ease;

      &:hover {
        filter: hue-rotate(-45deg) saturate(2.3);
      }
    }
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }

  100% {
    opacity: 1;
    left: 0;
  }
}

.primary-btn {
  padding: 15px 40px;
  border-radius: 50px;
  color: var(--dark);
  text-transform: uppercase;
  background-color: var(--primary);
  font-size: 24px;
  letter-spacing: 2px;
  border: none;
  outline: none;
  position: relative;
  overflow: hidden;

  @media (max-width: 992px) {
    padding: 5px 20px;
    font-size: 16px;
    letter-spacing: 0px;
    overflow: hidden;
  }

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: var(--secondary);
    transition: 0.25s ease;
    border-radius: 25px;
    z-index: -1;
  }

  &:hover {
    &:before {
      width: 100%;
    }
  }
}

.primary-btn i,
.primary-btn img {
  cursor: pointer;
}

.primary-btn:disabled {
  cursor: not-allowed;
}

.roadmap ul {
  list-style: none;
}

.roadmap ul li {
  position: relative;
  width: 0;
  border-left: 6px solid var(--secondary);
  border-radius: 50px;
  margin: 0 auto;
  transition: 0.5s ease;
}

.roadmap ul li:hover {
  transform: scale(1.025);
}

.roadmap ul h3 {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: var(--secondary);
  margin: 10px 0px 30px 0px;
}

.roadmap ul li > div {
  width: 350px;
  margin-bottom: 5px;
  text-align: center;
  border-radius: 15px;
  padding: 20px 15px;
  line-height: 22px;
  position: relative;
  transition: 0.5s ease;
}

.roadmap ul li:nth-child(2n + 0) > div {
  margin-left: 0px;
  transform: translateX(60px);
  background: linear-gradient(to left, var(--primary) 0%, var(--secondary) 80%);
}

.roadmap ul li:nth-child(4n + 0) > div {
  margin-left: -350px;
  transform: translateX(-60px);
  background: linear-gradient(
    to right,
    var(--primary) 0%,
    var(--secondary) 80%
  );
}

.roadmap ul li > div > div {
  position: relative;
  top: -14px;
  background: transparent;
  width: 250px;
  padding: 5px 10px;
}

.roadmap ul li.show:before {
  background: var(--dark);
}

@media (max-width: 992px) {
  .roadmap ul li > div,
  .roadmap ul li > div > div {
    width: 300px !important;
    margin: 15px 0px;
  }

  .roadmap ul li:nth-child(2n + 0) > div {
    margin-left: -125px !important;
  }

  .roadmap ul li:nth-child(4n + 0) > div {
    margin-left: -125px !important;
  }

  .roadmap ul h3 {
    display: none;
  }

  .roadmap ul li:nth-child(4n + 0) > div:before {
    transform: translateX(60px) !important;
  }
}

.faq-item {
  position: relative;
  margin: 25px;

  input {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    visibility: 0;
  }

  .title {
    cursor: pointer;
    padding: 25px;
    font-size: 28px;
    position: relative;
    align-items: center;
    text-transform: uppercase;
    transition: 0.25s ease;
    background: var(--secondary);
    color: var(--dark);
    box-shadow: 0px 0px 30px 6px rgba(var(--secondary-rgb), 0.2);
    border-radius: 86% 14% 86% 14% / 19% 73% 27% 81%;
  }

  .text {
    max-height: 0px;
    width: 50%;
    margin: 0 auto;
    overflow: hidden;
    text-align: center;
    font-size: 24px;
    transition: 0.25s ease;
    color: var(--light);
  }

  .text p {
    padding: 20px;
    padding-bottom: 0px;
    font-family: "Averia Sans Libre";
  }

  input:checked {
    transition: 0.25s ease;
  }

  input:checked ~ .text {
    max-height: 250px;
  }
}

.team-card {
  width: 350px;
  padding: 30px 0px;
  margin: 10px;
  border-width: 2px 2px 10px 2px;

  &:hover {
    .avatar {
      transform: scale(1.1);
    }
  }
}

.team-card-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.avatar {
  width: 150px;
  height: 150px;
  position: relative;
  transition: 0.25s ease;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.profile-name h1 {
  padding: 20px 0px 0px 0px;
  font-size: 28px;
  font-weight: 500;
  color: var(--light);
}

.profile-role {
  padding: 0px;
  text-transform: uppercase;
  color: var(--primary);
}

.team-card-footer {
  padding: 10px 0px;
}

.social-buttons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-buttons a {
  width: 40px;
  height: 40px;
  font-size: 23px;
  border: none;
  margin: 0 10px;
  padding-top: 4px;
  border-radius: 25px;
  background: var(--dark);
  transition: 0.25s ease;
  cursor: pointer;

  &:hover {
    background: var(--primary);

    i {
      color: var(--bg);
    }
  }
}

.social-buttons a i {
  cursor: pointer;
  color: var(--secondary);
  transition: 0.25s ease;
}

@media (max-width: 900px) {
  .roadmap ul li {
    margin: 0 50px;
  }

  .roadmap ul li > div,
  .roadmap ul li > div > div {
    width: calc(100vw - 275px);
  }

  .roadmap ul li:nth-child(4n + 0) > div {
    margin-left: 0px;
    transform: translateX(60px);
  }

  .roadmap ul li:nth-child(4n + 0) > div:before {
    transform: translateX(0);
    border-width: 8px 16px 8px 0;
    border-color: transparent var(--dark) transparent transparent;
  }

  .roadmap ul li.show > div {
    transform: none;
  }

  .faq-item .text {
    width: 100%;
  }
}

.mint-container {
  width: 40vw;
  padding: 50px;
  opacity: 0;

  @media (min-width: 390px) and (max-width: 992px) {
    min-height: 65vh;
    width: 90vw;
  }

  @media only screen and (max-width: 390px) {
    min-height: 90vh;
    width: 90vw;
  }

  @media (min-width: 390px) and (max-width: 390px) {
    min-height: 70vh;
    width: 90vw;
  }
}

footer {
  background-image: linear-gradient(
    to bottom,
    rgba(255, 0, 0, 0) 10%,
    rgba(var(--dark-rgb), 0.75) 100%
  );
  padding-top: 25px;
  padding-bottom: 25px;
  margin: 0;
  position: absolute;
  width: 100vw;

  div {
    font-weight: 600;
    margin: 20px;
    font-family: "Averia Sans Libre";
    color: var(--light);
  }

  a {
    padding: 8px 10px 6px 10px;
    cursor: pointer;
    border-radius: 25px;
    transition: 0.25s ease;

    i {
      cursor: pointer;
      color: var(--primary);
      font-size: 28px;
      transition: 0.25s ease;

      &:hover {
        color: var(--secondary);
      }
    }

    img {
      width: 30px;
      margin-top: -12px;
      cursor: pointer;
      transition: 0.25s ease;

      &:hover {
        filter: hue-rotate(-45deg) saturate(2.3);
      }
    }
  }
}
